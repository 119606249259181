<template>
  <div class="analytics-navigation">
    <div class="analytics-navigation__main">
      <nuxt-link to="/" class="analytics-navigation__company">
        <AppLogo class="analytics-navigation__company__logo" />
      </nuxt-link>

      <NavigationSidebarSection class="analytics-navigation__main-links" :items="mainSectionItems" />

      <SpacerBase></SpacerBase>

      <NavigationSidebarSection class="analytics-navigation__footer-links" :items="footerSectionItems" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useGoalConfigStore } from '~/packages/goal/store/config.store';
import NavigationSidebarSection from '~/shared/ui/app/app-navigation-sidebar-section.vue';
import AppLogo from '~/shared/ui/app/app-logo.vue';
import SpacerBase from '~/shared/ui/spacer-base.vue';
import { NavItem } from '~/shared/ui/app/app-navigation.inventory';
import { GoalUid } from '~/packages/goal/inventory/goal.inventory';

const router = useRouter();
const goalConfigStore = useGoalConfigStore();

// Функция для фильтрации ссылок
const filterLink = (item: NavItem) => {
  if (item.children) {
    return item.children.length > 0;
  }

  if (item.link) {
    return true;
  }

  const route = router.resolve(item.to || '');

  if (!route) {
    console.error('Undefined route in app navigation');
    return false;
  }

  const permissions = route.meta.permissions || item.permissions;
  return !permissions || userCan(permissions);
};

// Функция для определения ссылок
const defineLinks = (items: NavItem[]) => {
  return items.filter(filterLink);
};

// Основные элементы секции
const mainSectionItems = computed(() =>
  defineLinks([
    {
      label: 'People',
      icon: 'eva:people-outline',
      to: '/people',
    },
    {
      label: 'Use cases',
      icon: 'eva:bar-chart-outline',
      children: bigBetItems.value,
    },
    {
      label: 'Drivers',
      icon: 'eva:swap-outline',
      children: driverItems.value,
    },
    {
      label: 'Analysis',
      icon: 'eva:maximize-outline',
      children: productivityItems.value,
    },
    // {
    //   label: 'Attrition',
    //   icon: 'eva:people-outline',
    //   children: attritionItems.value,
    // },
    {
      label: 'Reports',
      icon: 'eva:file-text-outline',
      to: '/reports',
    },
    {
      label: 'Integrations',
      icon: 'eva:grid-outline',
      to: '/integrations',
    },
  ])
);

// Элементы нижней секции
const footerSectionItems = computed(() =>
  defineLinks([
    {
      label: 'Contact support',
      icon: 'eva:email-outline',
      link: 'mailto:support@performerty.ai',
    },
    {
      label: 'Logout',
      icon: 'eva:log-out-outline',
      to: '/logout',
    },
  ])
);

// Элементы секции revenue
const driverItems = computed(() =>
  defineLinks([
    {
      label: 'Revenue drivers',
      icon: 'eva:trending-up-outline',
      to: '/revenue-drivers',
    },
    {
      label: 'Cost drivers',
      icon: 'IconDollar',
      to: '/cost-drivers',
    },
    {
      label: 'Risk drivers',
      icon: 'eva:alert-triangle-outline',
      to: '/revenue-risks',
    },
    {
      label: 'Attrition drivers',
      icon: 'eva:people-outline',
      to: '/attrition/drivers',
    },
    {
      label: 'Attrition alerts',
      icon: 'eva:bulb-outline',
      to: '/attrition',
    },
  ])
);

// Элементы секции attrition
// const attritionItems = computed(() =>
//   defineLinks([
//     {
//       label: 'Alerts',
//       icon: 'eva:bulb-outline',
//       to: '/attrition',
//     },
//     {
//       label: 'Heatmap',
//       icon: 'IconHeatmap',
//       to: '/attrition/heatmap',
//     },
//     {
//       label: 'Drivers',
//       icon: 'eva:swap-outline',
//       to: '/attrition/drivers',
//     },
//     {
//       label: 'Results',
//       icon: 'eva:checkmark-circle-outline',
//       to: '/attrition/results',
//     },
//   ])
// );

// Элементы секции productivity
const productivityItems = computed(() =>
  defineLinks([
    {
      label: 'Productivity heatmap',
      icon: 'IconHeatmap',
      to: '/productivity/heatmap',
    },
    {
      label: 'Attrition heatmap',
      icon: 'IconHeatmap',
      to: '/attrition/heatmap',
    },
    {
      label: 'Trends',
      icon: 'eva:activity-outline',
      to: '/productivity/trends',
    },
    {
      label: 'Profiles',
      icon: 'IconUserProfile',
      to: '/profile/employee',
    },
  ])
);

// Элементы секции big bets
const bigBetItems = computed(() => {
  const items: Parameters<typeof defineLinks>[0] = [];

  goalConfigStore.availableGoals.forEach(goal => {
    if (goal.uid === GoalUid.EFFICIENT_GROWTH) {
      items.push({
        label: goal.name,
        icon: 'IconLineChart',
        to: '/big-bet/efficient-growth',
        // TODO: add permission
      });
    }
    if (goal.uid === GoalUid.REVENUE_GROWTH) {
      items.push({
        label: goal.name,
        icon: 'IconLineChart',
        to: '/big-bet/revenue-growth',
        // TODO: add permission
      });
    }
    if (goal.uid === GoalUid.CLIMATE_CHANGE) {
      items.push({
        label: goal.name,
        icon: 'eva:activity-outline',
        to: '/big-bet/climate-change',
        // TODO: add permission
      });
    }
  });

  items.push({
    label: 'Traveler analytics',
    icon: 'eva:people-outline',
    to: '/travel',
    // TODO: add permission
  });

  return defineLinks(items);
});
</script>

<style lang="scss">
@import '../../../app/style/vars/navigation';

.analytics-navigation {
  position: fixed;
  top: 0;
  left: 0;
  transition: $collapseNavigationTransition;
  bottom: 0;
  background: #fff;
  //padding: 0 15px;
  border-right: 1px solid theme('colors.grey.8');
  z-index: theme('zIndex.40');
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  height: 100%;

  // &::-webkit-scrollbar {
  //   display: none; /* for Chrome, Safari, and Opera */
  // }

  scrollbar-width: thin; /* for Firefox */

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    &__item {
    }
  }

  &__company {
    flex-grow: 0;
    flex-shrink: 0;
    height: 20px;
    padding: 0 15px 0 27px;
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    background: rgba(255, 255, 255, 0.01);
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/

    &--hidden {
      opacity: 0;
      display: none;
      /*height: 8px;*/
    }

    &__logo {
      opacity: 1;
      position: relative;
      height: 20px;
      //min-height: 28px;
      flex-shrink: 0;

      path {
        transition: $collapseNavigationTransition;
      }
    }
  }

  &__main-links {
    margin-top: 12px;
  }

  &__main-links,
  &__footer-links {
  }
}
</style>
